import shopifyPursuitGenericDefaults from '../shopify-pursuit-generic/config.js';

window.Convermax.addItemToCart = (e) => {
  e.preventDefault();

  fetch('/cart/add.js', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: window.theme.Helpers.serialize(e.target.closest('form')),
  })
    .then((response) => {
      return response.json();
    })
    .then(() => {
      const button = e.target.closest('.product-form__cart-submit');
      const addToCartBtnText = button.querySelector('[data-add-to-cart-text]');

      button.classList.add('added');
      addToCartBtnText.innerHTML = 'Added!';
      window.theme.Cart.prototype._onProductAdded();

      setTimeout(function () {
        button.classList.remove('added');
        addToCartBtnText.innerHTML = 'Add To Cart';
      }, 2000);
    });
};

const AfterInit = () => {
  const isSearchPage = window.location.pathname === '/pages/search';
  const isCollectionPage = window.location.pathname.startsWith('/collections/');
  const contentHeader = window.document.querySelector('.section-header');

  if ((isSearchPage || isCollectionPage) && contentHeader) {
    const addVehicleLabel = (vehicle, isSearchPage) => {
      const vehicleString = `${Object.values(vehicle).join(' ')}`;

      if (isSearchPage) {
        const header = window.document.querySelector('.page_header_heading');
        if (header) {
          header.innerHTML = `<div class="cm-vehicle-label">Vehicle Search Results<span class="cm-vehicle-label_title">${vehicleString ? `: <span class="cm-vehicle-label_vehicle">${vehicleString}</span>` : ''}</span></div>`;
        }
      } else {
        let vehicleLabel = window.document.querySelector('.cm-vehicle-label');

        if (!vehicleLabel) {
          vehicleLabel = window.document.createElement('div');
          vehicleLabel.classList.add('cm-vehicle-label');
          vehicleLabel.innerHTML = `<a href="/pages/search">< Shop all parts<span class="cm-vehicle-label_title">${vehicleString ? `&nbspfor <span class="cm-vehicle-label_vehicle">${vehicleString}</span>` : ''}</span></a>`;

          contentHeader.prepend(vehicleLabel);
        } else {
          window.document.querySelector('.cm-vehicle-label_title').innerHTML =
            `&nbspfor <span class="cm-vehicle-label_vehicle">${vehicleString}</span>`;
        }
      }
    };

    window.Convermax.onVehicleSelected = (vehicle) => addVehicleLabel(vehicle, isSearchPage);
    addVehicleLabel(window.Convermax.getVehicle(), isSearchPage);
  }
};

const onVehicleDiscarded = () => {
  const labelTitle = window.document.querySelector('.cm-vehicle-label_title');
  if (labelTitle) {
    labelTitle.innerHTML = '';
  }
};

export default {
  includes: ['shopify-pursuit-generic'],
  ...shopifyPursuitGenericDefaults,
  AfterInit,
  fitmentSearch: {
    ...shopifyPursuitGenericDefaults.fitmentSearch,
    onVehicleDiscarded,
    expectResponseRedirect: false,
  },
  Widgets: [
    ...shopifyPursuitGenericDefaults.Widgets.filter(
      (w) => !['CategoryPage', 'HeaderVehicleWidget', 'SearchVehicleWidget', 'FitmentTable'].includes(w.name),
    ),
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm',
        class: 'cm_vehicle-widget__header_desktop cm_mobile-hide',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 0,
    },
    {
      name: 'HomeVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm-mobile',
        class: 'cm_vehicle-widget__home_mobile',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 800,
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'HomeVehicleWidget_tablet',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm-tablet',
        class: 'cm_vehicle-widget__home_tablet',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 800,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: { replace: '.cm_fitment-table-tab', class: 'cm_tab-title cm_fitment-table-tab active' },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'InterchangesTab',
      type: 'ProductData',
      location: { replace: '.cm_interchange-tab', class: 'cm_tab-title cm_interchange-tab' },
      template: 'product/interchangesTab',
      fields: ['interchanges'],
    },
    {
      name: 'Interchanges',
      type: 'ProductData',
      location: '.cm_interchange-tab-content',
      template: 'product/interchanges',
      fields: ['interchanges'],
    },
  ],
};
